<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable vue/no-unused-vars -->
<template>
  <link href="https://fonts.googleapis.com/css?family=Pacifico" rel="stylesheet">
  <template v-if="useRuntimeConfig().public.SHOP_NAME=='LSDOPE'">
    <BgLava />
  </template>
  <template v-if="useRuntimeConfig().public.SHOP_NAME=='NyxKnacks'">
    <div class="backdiv backimg" />
    <div class="w-full h-screen bg-base-300 fixed opacity-60" />
    <!-- <img src="/public/grayscale_background.jpg" class="fixed opacity-40 backimg"> -->
  </template>
  <NuxtLoadingIndicator />
  
  <v-app>
    <ClientOnly>
      <v-navigation-drawer
        v-model="wishdrawer"
        temporary
      >
        <Wish />
      </v-navigation-drawer>
    </ClientOnly>
    <ClientOnly>
      <v-navigation-drawer
        v-model="themedrawer"
        temporary
      >
        <ToggleTheme />
      </v-navigation-drawer>
    </ClientOnly>
    
    
    <!-- v-if="!((useRoute().path=='/' || useRoute().path.startsWith('/shop/products/')) && $device.isDesktopOrTablet)"  -->
    <div
      v-if="!((useRoute().path=='/' || useRoute().path.startsWith('/shop/products/')) && $device.isDesktopOrTablet)"
      class="py-2 b-[100px] px-3 gap-4 z-10 flex justify-space-around align-center bg-base-200 w-full fixed rounded-none w-fill xs:top-3 xs:right-5 xs:rounded-full xs:w-auto elevation-4"
    >
      <v-icon
        icon="mdi-brightness-4"
        size="large"
        style="color: oklch(var(--bc));"
        @click="themedrawer = !themedrawer"
      />
      <NuxtLink to="/">
        <v-icon
          icon="mdi-home"
          size="large"
          style="color: oklch(var(--bc));"
        />
      </NuxtLink>
      <NuxtLink to="/account">
        <v-icon
          icon="mdi-account"
          size="large"
          style="color: oklch(var(--bc));"
        />
      </NuxtLink>
      <NuxtLink to="/checkout">
        <v-icon
          icon="mdi-cart"
          size="large"
          style="color: oklch(var(--bc));"
        />
      </NuxtLink>
      <v-icon
        icon="mdi-heart"
        size="large"
        style="color: oklch(var(--bc));"
        @click.stop="wishdrawer = !wishdrawer"
      />
      <!-- <NuxtLink to="/search">
        <v-icon
          icon="mdi-magnify"
          size="large"
          style="color: oklch(var(--bc));"
        />
      </NuxtLink> -->
    </div>
    <div v-if="$device.isMobile" class="relative mt-5">
      <v-app-bar
        elevation="3"
        class="text-base-content bg-base-200"
      >
        <NuxtLink
          to="/"
        >
          <template v-if="useRuntimeConfig().public.SHOP_NAME=='NyxKnacks'">
            <p class="pacifico text-2xl ml-3">
              NyxKnacks
            </p>
          </template>
          <template v-if="useRuntimeConfig().public.SHOP_NAME=='LSDOPE'">
            <p class="text-2xl ml-3 font-bold">
              LSDope
            </p>
          </template>
        </NuxtLink>
        <div class="absolute right-0">
          <NuxtLink to="/account">
            <v-btn
              icon="mdi-account"
            />
          </NuxtLink>
          <v-btn
            icon="mdi-heart"
            @click.stop="wishdrawer = !wishdrawer" 
          />
          <NuxtLink to="/checkout">
            <v-btn
              icon="mdi-cart"
            />
          </NuxtLink>
          <v-btn
            icon="mdi-brightness-4"
            @click="themedrawer = !themedrawer"
          />
        </div>
      </v-app-bar>
    </div>
    <!-- <div v-if="asidebar==true"> -->
    
    <!-- </div> -->

    <v-main order="0" class="">
      <ShopHeader v-if="!((useRoute().path=='/' || useRoute().path.startsWith('/shop/products/')) || $device.isMobile)" />
      <slot />       
    </v-main>
    <ShopFooter v-if="((useRoute().path=='/' || useRoute().path.startsWith('/shop/products/')) && $device.isDesktopOrTablet)" class="ml-[250px]" />
    <ShopFooter v-else class="absolute bottom sticky bg-base-200" />
  </v-app>
</template>

<script setup>
import { useTheme } from 'vuetify'
import { useUserDataStore } from '#imports';

const tabs = ref(['All'])
tabs.value = [
  'All',
  'Candles',
  'Mugs',
  'Puzzles',
  'Journals',
]

const wishdrawer = ref(null)
const themedrawer = ref(null)
// const daisyTheme = ref(useUserDataStore().userData.theme)
const theme = useTheme()
function toggleTheme () {
  theme.global.name.value = theme.global.current.value.dark ? 'myPurpleTheme' : 'myDarkTheme'
}
const daisyClick = (themeName) => {
  console.log(themeName +" clicked")
  useUserDataStore().userData.theme = themeName
}
  // if(useRoute().path=="/"){
    //on desktop
      //yes load ASideBar
      //no don't load topbar
      //no don't load ShopHeader
  // } else {
    //on desktop
      //no don't load sidebar
      //yes load topbar
      //yes load header
  // }

  //FOR EXACTING CLARITY
  //If the sidebar is there, we dont want topbar and header.
  //If we are on mobile, or on any page but index, we want topbar and header (not sidebar)
  //If we are on desktop index, we want sidebar, not topbar and header

  //SIDEBAR = Desktop Index ONLY
  //TOPBAR = Every page EXCEPT Desktop Index
  //HEADER = Every page EXCEPT Desktop Index, ONLY MOBILE on Index

  // const { isMobile } = useDevice()
</script>

<style scoped>

* {
  font-family: 'Raleway', sans-serif;
}

/* NYX KNACKS */
.backimg {
  background: #ffffff url(https://cdn.midjourney.com/0908391e-8871-4cd9-841f-1c46d6a4b959/0_0.png) center center/cover repeat;
}

.pacifico {
  font-family: 'Pacifico';
}

/* Not sure this needs to exist here */
.colors {
  height: 100px;
  width: 100%;
  padding: 15px;
  text-align: center;
}

.backdiv{
  height: screen;
  width: 100vw;
  position: fixed;
  top:0px;
  bottom: 0px;
  left:0px;
  right: 0px;
  z-index: 0;
} 


</style>