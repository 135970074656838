<template>
  <div
    class="text-base-content bg-base-200 mt-8 text-center p-5 text-sm flex flex-col gap-1 z-3"
  >
    <strong>{{ useRuntimeConfig().public.SHOP_NAME }} - © Strain Investment Co. LLC</strong>
    <div class="text-sm flex justify-center gap-2 flex-col md:flex-row md:gap-8">
      <NuxtLink
        to="/contact"
        class=""
      >
        Contact Us
      </NuxtLink>
      <NuxtLink
        to="/shop/faqs"
      >
        Frequently Asked Questions
      </NuxtLink>
      <NuxtLink
        to="/account"
        class=""
      >
        My Account
      </NuxtLink>
    </div>
    <p class="text-xs text-wrap">
      All content is owned solely by {{ useRuntimeConfig().public.SHOP_NAME=='NyxKnacks' ? 'Ashley' : 'Michael' }} Strain, and shall not be used or reproduced without prior written consent. All Rights Reserved.
    </p>
  </div>
</template>